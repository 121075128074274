import React from 'react';
import classNames from 'classnames';
import { flowRight } from 'lodash';
import { ConnectedProps } from 'react-redux';
import { Img } from '@wix/communities-image-lib';
import { Image } from '@wix/image';
import {
  MediaPlatformImage,
  getImageUri,
  isExperimentEnabled,
} from '@wix/communities-blog-client-common';
import { EXPERIMENT_USE_WOW_IMAGE_MOBILE } from '@wix/communities-blog-experiments';
import useOnScreen from '../../hooks/use-on-screen';
import {
  DEFAULT_MOBILE_VIEWPORT_WIDTH,
  getMobileViewportHeight,
} from '../../services/viewport';
import {
  isEditor as getIsEditor,
  isSSR as getIsSSR,
} from '../../store/basic-params/basic-params-selectors';
import { WithResponsiveContext } from '../responsive-listener/responsive-context';
import withResponsiveContext from '../responsive-listener/with-responsive-context';
import { connect } from '../runtime-context';
import { getImageDimensions } from './get-image-dimensions';
import styles from './post-list-item-image.scss';

type OwnProps = {
  image: Omit<MediaPlatformImage, 'width' | 'height'> &
    Required<Pick<MediaPlatformImage, 'width' | 'height'>>;
  url?: string;
  isPublic?: boolean;
  width: number;
  height: number;
  layoutName: string;
  useWowImageMobileExperimentEnabled: boolean;
};

type Props = OwnProps &
  WithResponsiveContext &
  ConnectedProps<typeof connector>;

export const PostListItemImageMobile: React.FC<Props> = ({
  image,
  url,
  isPublic,
  width,
  height,
  layoutName,
  rootWidth,
  isSSR,
  isEditor,
  useWowImageMobileExperimentEnabled,
}) => {
  const { isOnScreen, ref } = useOnScreen(getMobileViewportHeight(), isEditor);

  const dimensions = getImageDimensions({
    container: { width, height },
    component: { width: rootWidth },
    layoutName,
    isSSR,
  });

  if (useWowImageMobileExperimentEnabled) {
    const uri = getImageUri(image);
    const aspectRatio = image.height / image.width;
    const targetWidth = dimensions.width || DEFAULT_MOBILE_VIEWPORT_WIDTH;
    const targetHeight =
      dimensions.height || Math.floor(targetWidth * aspectRatio);

    return (
      <div
        className={classNames(styles.container, styles.mobile)}
        style={{ height: targetHeight }}
      >
        {uri ? (
          <Image
            alt=""
            className={styles.image}
            uri={uri}
            displayMode="fill"
            width={image.width}
            height={image.height}
            targetWidth={targetWidth}
            targetHeight={targetHeight}
            data-hook="post-list-image"
            socialAttrs={
              isPublic ? { 'data-pin-url': url } : { 'data-pin-nopin': true }
            }
          />
        ) : undefined}
      </div>
    );
  }

  return (
    <div className={classNames(styles.container, styles.mobile)}>
      <Img
        src={image}
        className={styles.image}
        ref={ref}
        isOnScreen={isOnScreen}
        alt=""
        encode={true}
        {...dimensions}
        {...(isPublic ? { 'data-pin-url': url } : { 'data-pin-nopin': true })}
      />
    </div>
  );
};

const connector = connect((state) => ({
  isSSR: getIsSSR(state),
  isEditor: getIsEditor(state),
  useWowImageMobileExperimentEnabled: isExperimentEnabled(
    state,
    EXPERIMENT_USE_WOW_IMAGE_MOBILE,
  ),
}));

export default flowRight(
  connector,
  withResponsiveContext,
)(PostListItemImageMobile);
